import actionTypes from "../actionTypes";

export const paginasGet = (language, name) => {
	return {
		type: actionTypes.PAGINAS_LOAD_INIT,
		payload: {
			language,
			name,
		},
	};
};
