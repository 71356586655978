import React from "react";
import { Location } from "@reach/router";
import l from "lodash";

const withPathParts = ComponentToWrap => props => {
	return (
		<Location>
			{({ location, navigate }) => {
				const path = l.trim(location.pathname, "/");
				const pathParts = path.split("/");
				const urlParts = [];
				for (var idx = 0; idx < pathParts.length; idx++) {
					urlParts.push(pathParts[idx]);
				}
				return <ComponentToWrap {...props} location={location} navigate={navigate} urlParts={urlParts} />;
			}}
		</Location>
	);
};

export const createPathParts = parts => {
	var path = l.join(parts, "/");
	return path;
};

export default withPathParts;
