import React, { useEffect } from "react";
import BodyClassName from "react-body-classname";
import Layout from "../components/layout";
import Footer from "../components/footer";
import SEO from "../components/seo";
import { useIntl, Link, navigate } from "gatsby-plugin-intl";
import { connect } from "react-redux";
import { paginasGet } from "../redux/paginas/actions";
import { appUISidenavToggle, appRedirect, appUIPopupLoginShow } from "../redux/app/actions";
import withPathParts from "../hocs/withPathParts";
import ReactHtmlParser from "react-html-parser";

const Paginas = ({
	pagina,
	loading,
	loggedIn,
	authResolved,
	usuario,
	sideNav,
	paginasGet,
	appUISidenavToggle,
	appRedirect,
	appUIPopupLoginShow,
	urlParts,
	s,
}) => {
	const intl = useIntl();

	useEffect(() => {
		const name = urlParts[urlParts.length - 1];
		paginasGet(intl.locale, name);
	}, []);

	const onHeaderGeoLocationChange = (data) => {
		navigate(
			`/anuncios/mode/geo/lat/${data.lat}/long/${data.long}/geo_descripcion/${data.descripcion}/orden/distancia`
		);
	};

	const crearAnuncio = () => {
		if (sideNav) appUISidenavToggle();
		if (authResolved && loggedIn) {
			appRedirect({ to: usuario.completo ? `/crearanuncio` : `/editarcuenta` });
		} else {
			appUIPopupLoginShow();
		}
	};

	const transform = (node, index) => {
		if (node.type === "tag" && node.name === "actionbutton") {
			return (
				<button className="button button-inline red-bg white-text" onClick={crearAnuncio}>
					{node.attribs.label}
				</button>
			);
		}
	};

	return (
		<>
			{pagina && !loading && (
				<BodyClassName className="paginas">
					<Layout onHeaderGeoLocationChange={onHeaderGeoLocationChange}>
						<SEO title={pagina.title} />
						<main className="page">
							<article className="bg-img">
								<h1 className="white-text prequel-font">{pagina.title}</h1>
								<picture>
									<source srcSet={pagina.imagen?.url} media="(min-width: 768px)" alt={pagina.title} />
									<img srcSet={pagina.imagen_mobile?.url} alt={pagina.title} />
								</picture>
							</article>

							<article className="box bg-white text">
								<div className="wrapper-text">
									<ul className="breadcrumbs white-bg">
										<li className="link">
											<Link to="/" className="ltblue-text-02">
												Home
											</Link>
										</li>
										<li className="active">
											<p className="lightgray-text-07">{pagina.title}</p>
										</li>
									</ul>
								</div>
								<br />
								<br />
								<p className="box pagina-body">{ReactHtmlParser(pagina.body, { transform })}</p>
								<br />
								<br />
							</article>
							<Footer />
						</main>
					</Layout>
				</BodyClassName>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	const { pagina, loading } = state.paginas;
	const { loggedIn, resolved: authResolved, usuario } = state.auth;
	const { sideNav } = state.app.ui;
	return {
		pagina,
		loading,
		loggedIn,
		authResolved,
		usuario,
		sideNav,
		s: state.app.boot.strings,
	};
};

const mapDispatchToProps = {
	paginasGet,
	appUISidenavToggle,
	appRedirect,
	appUIPopupLoginShow,
};

export default connect(mapStateToProps, mapDispatchToProps)(withPathParts(Paginas));
